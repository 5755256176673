<template>
 
  <div class="modal_user_content">

    <div class="content_profile">

      <div class="settings_description">

        <div class="row">
          <div class="label">
            {{ $t('profile_menu_edit_profile_delete_text') }} 
          </div>
          <div class="field_text">
            <div class="btn warning" @click="removeAccount">
              {{ $t('profile_menu_edit_profile_button_delete') }} 
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
 

</template>

<script setup>
 
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $popup, $ga} = useNuxtApp()


const props = defineProps({
  userId: {
    type: Number
  }
})

const removeAccount = function(){
  if(confirm('Delete account?')){
    $api.deleteProfileAccount(props.userId)
    .then((res) => {
      $popup.success('Account deleted');
      useAuth().logout();
    })
    .catch(function (error) {

    });
  }
}


</script>

<style scoped>

.content_profile {
    
}

.modal_user_content .settings_description {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    vertical-align: middle;
    margin-right: auto;
    width: 100%;
    position: relative;
}

.modal_user_content .delete_profile_btn{
    box-shadow: 0px 0px 0px 1px #db2828 inset !important;
    color: #DB2828 !important;
    background: transparent none !important;
    font-weight: normal;

    float: right;
}

.modal_user_content .settings_description .row{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal_user_content .settings_description .label{
    display: inline-block;
    min-width: 280px;
}

.modal_user_content .settings_description .field_text{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.modal_user_content .settings_description .field_text .btn{

  margin-top: 1rem;
}

</style>
